.header{
    /* width: 100vw;  */
    background-color: #1A1A1A;
    margin: 0 auto;
    min-width: 300px;
}

.headerImg{
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 473px;
    margin: 0 auto;
    /* background-image: url('../../assets/header.png');
    
    height: 100%;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    /* max-height: 473px; */
}

@media(max-width: 768px){
    .headerImg{
        content: url('../common/assets/headerMob.png');
    }
}

.linkphone{
    color: white;
    margin-left: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.4em;
}
.imgLogo{
    margin-left: 30px;
}