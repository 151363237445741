.mainContainer{
    background-color: var(--e-bground-color-dark);
    width: 100%;
    height: 100%;
    padding: 100px 0 50px 0;
    margin-top: 150px;
}
.containerContact{
    margin: 0 auto;
    max-width: var( --max-width-container);
}
.containerContact>h3{
    color: white;
    font-family: "Roboto", Sans-serif;
    font-size: 100px;
    font-weight: 700;
    line-height: 1.2em;
    margin-left: 50px;

}
.containerFlexInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    font-size: 18px;
}

.flexFooter{
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    background-color: var( --e-global-color-accent);
    margin: 10px;
    color: white;
    padding: 40px;
    flex-direction: row;
    align-items: flex-end;
    font-size: 18px;
    flex-wrap: wrap;
}
.flexFooter>div:nth-child(1){
margin-right: 200px;
}
.info{
    background-color: var( --e-global-color-accent);
    margin: 10px;
    padding: 40px;
    flex-grow: 1;

}

.info>a{
    text-decoration: none;
    color: white;
    }

.headerGreen{
    color: var( --e-global-color-04f8b12);
}

.containerfooter{
    max-width: 1600px;
}

@media screen and (max-width: 1300px) {}
@media screen and (max-width: 1024px) {
    .containerContact>h3{
    font-size: 90px
    }
}
@media screen and (max-width: 850) {
    .containerContact>h3{
        margin-left: 20px;
        font-size: 85px;
    }
}
@media screen and (max-width: 767px) {
    .containerContact>h3{
        font-size: 70px;
        margin-left: 20px;
    }
}
@media screen and (max-width: 535px) {
    .containerContact>h3{
        font-size: 60px;
        margin-left: 20px;
    }
}
