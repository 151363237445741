.mainContainer{
    width: 100%;
    margin: 200px 0 50px 0;
    height: 100%;
}
.Stackcontainer{
    max-width: var(--max-width-container);
    margin: 0 auto;
    height: 100%;
}

.Stackcontainer>h3{
    color: ( --e-bground-color-dark);
    font-family: "Roboto", Sans-serif;
    font-size: 80px;
    font-weight: 700;
    line-height: 1.2em;
    margin: 10px;

}
.Stackcontainer>h3::after{
    content: " используемых технологий";
    color: var(--e-global-color-accent);
}
.infoContainer{  
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.infoContainer > .contant:nth-child(even){
    background-color: var( --e-bground-color-dark);
}

.contant{
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 40px;
    background-color: var( --e-global-color-accent); 
    margin: 10px;
    color: white;
    font-size: 18px;
    flex-grow: 1;
    flex-basis: 0;
    line-height: 1.4;
}
.contant>p{
    font-size: 22px;
    padding: 0;
    margin: 0;
}
.contantul{
    padding: 0;
    list-style-type: decimal;

}

.contantul> ul{
    padding: 0;
    list-style-type: none;
}

.referenceP{
    padding: 0;
    margin: 0 0 0 40px;
    font-size: 16px;
    line-height: 1.4;
}

@media screen and (max-width: 1024px) {
    .infoContainer{  
        flex-direction: column;
        padding-inline-start: 30px;
        padding-inline-end: 30px;
    }

}

@media screen and (max-width: 850px) {
    .Stackcontainer>h3{
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        font-size: 75px;    
    }
}

@media screen and (max-width: 767px) {
    .Stackcontainer>h3{
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        font-size: 55px;    
    }
    .infoContainer{  
        padding-inline-start: 20px;
        padding-inline-end: 20px;
    }
}

@media screen and (max-width: 535px) {
    .Stackcontainer>h3{
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        font-size: 35px;    
    }
    

}