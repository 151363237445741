.mainContainer{

}
.containerInfo{
    max-width: var(--max-width-container);
    margin: 0 auto;
    padding: 0 20px;
}
.header{
    display: inline-block;
    color: var( --e-bground-color-dark);
    font-family: "Roboto", Sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2em;
    margin: 50px 0 0 0;
}
.spanForditail{

    color: var( --e-global-color-accent);
}

.listCountry{
    columns: 3 auto;
    border-top: 1px solid black;
    list-style-type: none;
    padding: 20px 0;
}
.listCountry>li{
    line-height: 1.8;
    font-size: 18px;
}
.linkCountry{
    color: #06c;
    text-decoration: none;
}
.linkCountry:hover, .linkCountry:active{
    border-bottom: 1.5px solid #06c;
}

.containerImgDownload{
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.imgDownload{
    width: 20px;
    margin: 0 10px;
}
.linkfordowload{
    display: flex;
    flex-direction: row;
    max-width: 300px;
    padding: 20px;
    background-color: var( --e-bground-color-dark);;
    color: white;
    border-radius: 10px;
    text-decoration: none;
    font-size: 25px;
    align-items: center;
}
@media screen and (max-width: 767px) {
    .header{
        margin: 30px 0 0 0;
        font-size: 40px;
    }
    .listCountry{
        columns: 1;
    }

    .linkfordowload{
        /* padding: 10px; */
        font-size: 18px;
    }
}
@media screen and (max-width: 535px) {
    .header{
        margin: 20px 0 0 0;
        font-size: 30px;
    }
    .listCountry{
        columns: 1;
    }
    .containerImgDownload{
        flex-direction: column;
        justify-content: left;
        /* align-items: flex-start; */
    }
    /* .imgDownload{
        width: 150px;
    } */
}