.containerArticle{
max-width: 720px;
padding: 0 20px;
margin: 0 auto;
line-height: 1.5;
}

.headerArticle{
    margin:  30px 0;
    padding: 0;
}
.paragrafArticle{
    margin: 15px 0;
    font-size: 20px;
}
.img{
    width: 100%;
    /* margin: 0 auto;
    display: block; */
}
.errorheader{
    font-size: 50px;
    text-align: center;
}

.robot{
    width: 400px;
        margin: 0 auto;
    display: block;
}

.loading{
    text-align: center;
    margin-top: 100px;
}
/*
 @media screen and (max-width: 850px) {
    .headerArticle{
        font-size: 28px;
    }
    .img{
        width: 650px;
    }
}

@media screen and (max-width: 767px) {
    .img{
        width: 300px;
    }
}
*/
@media screen and (max-width: 535px) {
    .headerArticle{
        font-size: 28px;
    }
} 