.mainContainer{

}

.silencingPage{
    text-align: center;
    max-width: var(--max-width-container);
    margin: 0 auto;
    padding: 0 20px;
}
.silencingPage>h4{
    font-size: 50px;
}