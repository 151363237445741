.mainContainer{

}



.containerImgDownload {
    width: 640px;
    display: flex;
    height: 100%;
    gap: 20px;
    margin: 50px auto;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.imgDownload {
    border-radius: 10px;
    width: 300px;
}


@media screen and (max-width: 767px) {
    .containerImgDownload {
        width: 320px;
        display: flex;
        height: 100%;
        gap: 20px;
        margin: 50px auto;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .imgDownload{
        width: 280px;   
    }
}

@media screen and (max-width: 535px) {
    .containerImgDownload {
        width: 290px;
    }
    .imgDownload {
        width: 250px;
    }

}