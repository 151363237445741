.mainContainer{
margin: 100px 20px;
}
.errorContainer{
    margin: 0 20px;
}
.container {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-width-container);
}
.opencontainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.opencontainerError{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center; 
}

.openbutton {
    position: relative;
    outline: none;
    height: 240px;
    text-align: center;
    width: 240px;
    border-radius: 50%;
    background: #ffc74f; 
    border: none;
    color:  #ffc74f;
    letter-spacing: 1px;
    text-shadow: 0;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.openbuttonerror, .openbuttonerror:disabled{
    background: white; 
    box-shadow: none;
    border: none;
} 

.openbutton:after {
    content: "";
    display: block;
    height: 210px;
    width: 190px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 200px;
    background-image: url(../../common/assets/lock.png);
    margin: 0  auto;
    background-position: center;
}
.openbuttonerror:after {
    content: "";
    display: block;
    height: 195px;
    width: 160px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 140px;
    background-image: url(../../common/assets/close.png);
    margin: 0  auto;
    background-position: center;
}


.onclic {
    position: absolute;
    border-width: 5px;
    height: 230px;
    width: 230px;
    border-radius: 50%;
    border-style: solid;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-left-color:  rgb(253, 253, 253);
    animation: rotating 2s 0.25s linear infinite;
}

.validate {
    font-size: 60px;
    color: white;
    background: var(--e-global-color-accent);
    transition: all 0.5s ease-out; 
}
.doorOpen{
    background: #1ec182; 
    border: none;
    color:  #1ec182;
}
.doorOpen:after{
    content: "";
    display: block;
    height: 200px;
    width: 170px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 150px;
    background-image: url(../../common/assets/unlock.png);
    margin: 0  auto;
    background-position: center;
}

.erroropen{
    background: rgb(168, 39, 39); 
    box-shadow: 0 0 35px 10px rgb(190, 190, 190);;
    border: 2px solid rgb(190, 190, 190);
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.messageError{
    color: #580917;
    padding: 10px;
    text-align: center;
    border-radius: 20px;
    max-width: 350px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;

}
.messageOk{
    padding: 10px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    color: black;
    font-weight: 700;
}

.loading{
    text-align: center;
    margin-top: 100px;
}

@media screen and (max-width: 1024px) {
    .error404{
        width: 400px;
    }
}

@media screen and (max-width: 850px) {
    .error404{
        width: 350px;
    }
}

@media screen and (max-width: 767px) {
    .error404{
        width: 300px;
    }
    .messageOk{
        font-size: 35px;
    }
}

@media screen and (max-width: 535px) {
    .error404{
        width: 250px;
    }
}