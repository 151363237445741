.ulMenu{
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    text-align: right;
    margin-right: 30px;
    justify-content: flex-end;

}

.linkMenu{
    text-decoration: none;
    color: white;
    border-bottom: 2px solid var(--e-global-color-accent);
    margin-left: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.4em;
    padding-bottom: 2px;
}
.linkphone{
    color: white;
    margin-left: 50px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.4em;
    text-decoration: none;

}
.linkMenu:hover{
    border-bottom: 5px solid var(--e-global-color-accent);
}

/*Контейнер для шапки*/
.navbarContainer{
    max-width: var(--max-width-container);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    margin-left: auto;
    margin-right: auto;
}
.navbarContainer>.navMenu{
    /* float: right; */
    width: 90%;
}
.menubutton{
    display: none;
}
.minnavMenu{
    display: none;
}

.imgLogo{
padding: 0 0 0 20px;
}

@media screen and (max-width: 767px) {
    /* .navbarContainer{
        max-width: var(--min-width-container);
            margin-left: 0;
            margin-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
    } */
    .navMenu{
        display: none;
    }
    .menubutton{
        display: block;
        background: none;
        border: none;
        cursor: pointer;
    }
    .backMenu{
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9999999999;

    }
    .minnavMenu{
        display: block;
        position: absolute;
        top:0;
        width: 90%;
        height: 100%;
        padding: 50px 20px 50px 20px;
        background-color: white;
        z-index: 9999999999;
        
    }
    .buttonclose{
        position: absolute;
        right: 10%;
        top: 20px;
    }
    .minulMenu{
        list-style-type: none;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0;
        color:var( --e-global-color-04f8b12);
    }
    .minlinkMenu{
        line-height: 2rem;
    }
    .minlinkphone{
        color: var(--e-global-color-accent );
        font-size: 16px;
        font-weight: 700;
        line-height: 3em; 
        text-decoration: none;
    }
    .nimimgLogo{
        width: 150px;
    }

}
@media screen and (max-width: 535px) {
.buttonclose{
    position: absolute;
    right: 20%;
    top: 20px;
}
}