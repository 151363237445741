.containerCountryDescription {
    max-width: 840px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.containerCountryDescriptionText{
    max-width: 840px;
    margin: 0 auto;
    text-align: left;
    margin-top: 15px;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.containerdescription{
    /* display: flex;
    flex-direction: row; */
    align-items: baseline;
    gap: 3px;
}
.mainContainer{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}
.containetText{
    text-align: center;
}


.imgCountry, 
.imgCountryes {
    width: 400px;
    margin-top: 30px;
}

.titlecountry{
    font-weight: bold;
    font-size: 30px;
    margin: 0 10px;
    padding: 0;
}

.dicriptionCountry{
padding: 0;
margin: 0 ;
}

.dicriptionBlodDkeys::before{
content: "D-KEYS ";
font-weight: bold;
color: black;
}
.dicriptionBlodPhone::before{
    content: "Հեռախոսահամարներ՝ ";
    font-weight: bold;
    color: black;  
}
.dicriptionBlodNumber::before{
    content: "Մեսենջեր ";
    font-weight: bold;
    color: black;  
}

.iconstyle{
    width: 20px;
    height: 20px;
    margin-right: 5px ;
}
.icontelegram{
    width: 17px;
    height: 17px;
}
@media screen and (max-width: 1024px) {
    .containerCountryDescriptionText{
        padding: 20px;
        font-size: 18px;
    }
    .iconstyle{
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 850px) {
    
    .iconstyle{
        width: 15px;
        height: 15px;
    }
    .icontelegram{
        width: 13px;
        height: 14px;
    }
}

@media screen and (max-width: 768px) {
    .imgCountryes{
        width: 500px;
    }
    .titlecountry{
        font-size: 30px;
    }

    .iconstyle{
        width: 13px;
        height: 13px;
    }
    .icontelegram{
        width: 11px;
        height: 12px;
    }
}

@media screen and (max-width: 535px) {
    .imgCountry {
        width: 350px;
    }
    .imgCountryes{
        width: 350px;
    }
    .titlecountry{
        font-size: 20px;
    }

}