.error404{
    width: 500px;
}
.container{
    width: 100%;
    text-align: center;
}
.container>h3{
    color: #580917;
    padding: 0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin: 0;

}
@media screen and (max-width: 1024px) {
    .error404{
        width: 400px;
    }
}

@media screen and (max-width: 850px) {
    .error404{
        width: 350px;
    }
}

@media screen and (max-width: 767px) {
    .error404{
        width: 300px;
    }
    .container>h3{
        font-size: 25px;
    }
}

@media screen and (max-width: 535px) {
    .error404{
        width: 250px;
    }
}