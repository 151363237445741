@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
:root{
 --e-global-color-accent: #004438;
 --max-width-container: 1300px;
 --min-width-container: 550px;
 --e-bground-color-dark: #1A1A1A;
 --e-global-color-04f8b12: #006B53;
}
html{
  min-width: 300px;
}
body {
  background-color:white;
  font-family: "Roboto", Sans-serif;
  min-width: 300px;
}

.App {
  /* display: flex;
  flex-direction: column;
  flex-wrap: nowrap; */
  width: 100%; 
  display: grid;
  grid-template-columns: auto minmax(auto, 1300px) auto;
  grid-template-areas: "header header header"
  ". main .";
   /* max-width: 1300px; */
  /* height: 100%; */
 
}
.header{
  grid-area: header;
}
.main{
  grid-area: main;
  background-color:white;

}

/*Для слайдера*/
.slick-prev:before,
.slick-next:before {
  display: none;
}
.slick-dots li.slick-active button:before{
  color: white !important;
  font-size: 8px !important;
  width: 30px !important;
  height: 30px !important;
}
.slick-slide{
  transition: transform 1s ease-in-out;
}

.rs-panel-group-bordered{
border: none !important;
margin: 40px;
}
.rs-panel-btn{
  font-size: 30px !important;
  font-weight: 700;
  color: white !important;
}
.rs-panel-btn:hover, .rs-panel-btn:focus, .rs-panel-btn:active {
  background-color: transparent !important;
  color: white !important;
  outline: transparent solid 2px !important;
}
.rs-panel-group > .rs-panel + .rs-panel::before {
  content: '';
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid #e5e5ea !important;
  border-bottom: 1px solid var(--rs-border-primary) !important;
  left: 20px;
  right: 20px;
}
.rs-panel-header, .rs-panel-body {
  padding: 0;
}
.rs-panel-group-bordered{
  margin: 0 !important;
}

@media(max-width: 768px){
  .App {

    width: 100%; 
    display: grid;
    grid-template-columns: auto minmax(auto, 1300px) auto;
    grid-template-areas: "header header header"
    " main main  main";

   
  }
}